import React, { useState, useEffect, useRef } from "react";
import { operation } from "./components/estructuraBasica";
import { options } from "./components/opciones-1-9";
import { BarraStatus } from "./components/elementos/barraStatus";
import { MdDone, MdClose, MdArrowForward } from "react-icons/md";
import CanvasDraw from "react-canvas-draw";
import "./fonts/Cursive_standard.eot";

export const Actividad = ({ tipo, dificultad, setMensaje, setShowActividades }) => {
  const [result, setResult] = useState("");
  const [aciertos, setAciertos] = useState(100);
  const [fallos, setFallos] = useState(100);
  const [numbers, setNumbers] = useState([]);
  const [operator, setOperator] = useState("");
  const dataRef = useRef(null);

  const configurarActividad = () => {
    if (tipo === "suma") {
      setOperator("+");
      if (dificultad === 1) {
        let n1 = Math.floor(Math.random() * 5) + 1;
        let n2 = Math.floor(Math.random() * 4) + 1;
        setNumbers([n1, n2]);
      }
      if (dificultad === 2) {
        let n1 = Math.floor(Math.random() * 79) + 10;
        let n2 = Math.floor(Math.random() * 9) + 1;
        setNumbers([n1, n2]);
      }
      if (dificultad === 3) {
        let n1 = Math.floor(Math.random() * 40) + 10;
        let n2 = Math.floor(Math.random() * 45) + 1;
        setNumbers([n1, n2]);
      }
    }
    if (tipo === "resta") {
      setOperator("-");
      if (dificultad === 1) {
        let n1 = Math.floor(Math.random() * 4) + 5;
        let n2 = Math.floor(Math.random() * 3) + 1;
        setNumbers([n1, n2]);
      }
      if (dificultad === 2) {
        let n1 = Math.floor(Math.random() * 89) + 10;
        let n2 = n1 - Math.floor(Math.random() * 9) + 1;
        setNumbers([n1, n2]);
      }
      if (dificultad === 3) {
        let n1 = Math.floor(Math.random() * 89) + 10;
        let n2 = Math.floor(Math.random() * 9) + 1;
        setNumbers([n1, n2]);
      }
    }

    if (tipo === "multiplicacion") {
      setOperator("x");
      if (dificultad === 1) {
        let n1 = Math.floor(Math.random() * 2) + 2;
        let n2 = Math.floor(Math.random() * 2) + 2;
        setNumbers([n1, n2]);
      }
      if (dificultad === 2) {
        let n1 = Math.floor(Math.random() * 5) + 4;
        let n2 = Math.floor(Math.random() * 5) + 3;
        setNumbers([n1, n2]);
      }
    }

    if (tipo === "trazo") {
      if (dificultad === 1) {
        let n1 = Math.floor(Math.random() * 9) + 1;
        setNumbers([n1]);
      }
      if (dificultad === 2) {
        function randomLetter() {
          var result = "";
          var characters = "aeiouAEIOU";
          var charactersLength = characters.length;
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          return result;
        }
      }
      if (dificultad === 3) {
        function randomLetter() {
          var result = "";
          var characters = ["ma", "me", "mi", "mo", "mu"];
          var charactersLength = characters.length;
          //result += characters[Math.floor(Math.random() * charactersLength)];
          //return result;
          return characters;
        }

        let n1 = randomLetter();
        setNumbers([n1]);
      }
    }
  };

  const checkOperation = () => {
    let res = 0;
    if (tipo === "suma") {
      numbers.map((n) => {
        res = n + res;
      });
    }
    if (tipo === "resta") {
      numbers.map((n, index) => {
        if (index === 0) res = n;
        else res = res - n;
      });
    }
    if (tipo === "multiplicacion") {
      res = 1;
      numbers.map((n) => {
        res = n * res;
      });
    }
    return res;
  };

  const verificarResultado = () => {
    let res = checkOperation();
    console.log(result, res);
    setResult(
      result == res ? (
        <>
          <MdDone style={{ float: "left", color: "darkgreen", fontSize: "50px" }} />
          {result}
        </>
      ) : (
        <>
          <MdClose style={{ float: "left", color: "darkred", fontSize: "50px" }} />
          {result}
        </>
      )
    );

    result == res ? setAciertos(aciertos - 10) : setFallos(fallos - 10);
    if (aciertos - 10 === 0 || fallos - 10 === 0) {
      setMensaje(
        <p>
          <b>¡Ejercicio terminado!</b>
          <p>{10 - aciertos / 10} aciertos</p>
        </p>
      );
      setShowActividades(true);
    } else {
      setTimeout(() => {
        setResult("");
        configurarActividad();
      }, 1000);
    }
  };

  const updateResult = (e) => {
    setResult(e + "" + result);
  };

  const handleTramo = () => {
    const data = dataRef.current.getSaveData();
    console.log(data);
    setTimeout(() => {
      dataRef.current.clear();
      configurarActividad();
    }, 1000);
  };

  const clearTramo = () => {
    dataRef.current.clear();
  };

  useEffect(() => {
    let longPrueba = dificultad;
    if ((tipo === "suma" || tipo === "resta") && dificultad === 3) longPrueba = 2;
    if (longPrueba === result?.length) {
      verificarResultado(result);
    }
  }, [result]);

  useEffect(() => {
    configurarActividad();
  }, [tipo, dificultad]);

  return (
    <>
      {(tipo === "suma" || tipo === "resta" || tipo === "multiplicacion") && (
        <>
          <div style={{ width: "70%", margin: "0 auto", maxWidth: "320px", marginTop: "70px" }}>
            <BarraStatus valor={aciertos} color={"rgba(82, 163, 141, 1)"} deg={"270"} text={"Aciertos"} />
          </div>
          {operation(numbers, operator, options(updateResult), result)}
        </>
      )}
      {tipo === "trazo" && (
        <div style={{ position: "relative", width: "340px", margin: "15% auto" }}>
          <CanvasDraw ref={dataRef} canvasWidth={350} canvasHeight={500} style={{ margin: "5% auto" }} />
          <p style={{ fontSize: "2rem", position: "absolute", margin: "-517px 0px", border: "1px lightgrey solid", padding: "13px", backgroundColor: "#f8f8f8", width: "85px", textAlign: "center" }}>
            {numbers[0]}
          </p>
          <div style={{ textAlign: "center", position: "absolute", left: "125px", top: "0" }}>
            <button
              onClick={clearTramo}
              style={{ backgroundColor: "white", border: "1px solid cornflowerblue", padding: "20px 0px", width: "100px", cursor: "pointer", width: "100px", color: "cornflowerblue" }}
            >
              <MdClose style={{ fontSize: "2rem" }} />
            </button>
          </div>
          <div style={{ textAlign: "center", position: "absolute", right: "0", top: "0" }}>
            <button onClick={handleTramo} style={{ backgroundColor: "white", border: "1px solid #52a38d", padding: "20px 0px", width: "100px", cursor: "pointer", color: "#52a38d" }}>
              <MdArrowForward style={{ fontSize: "2rem" }} />
            </button>
          </div>
          {console.log("dificultad: " + dificultad)}
          {dificultad < 3 ? (
            <p style={{ fontFamily: "escolar_dotted", fontSize: "27rem", position: "", margin: "-450px 90px" }}>{numbers[0]}</p>
          ) : (
            <p>
              <p style={{ fontFamily: "escolar_dotted", fontSize: "8rem", position: "", margin: "-150px 10px" }}>{numbers[0][0]}</p>
              <p style={{ fontFamily: "escolar_dotted", fontSize: "8rem", position: "", margin: "-150px 180px" }}>{numbers[0][1]}</p>
            </p>
          )}
        </div>
      )}
    </>
  );
};

import "./style.css";
import { MdArrowBack } from "react-icons/md";

export const Menu = ({ initScreen }) => {
  return (
    <div className="blockMenu">
      {/*<div className="itemMenu" onClick={() => (window.location.href = "https://webdelmaestro.com")}>
        <MdArrowBack /> BLOG
      </div>*/}
      <div className="itemMenu" onClick={() => initScreen()}>
        ACTIVIDADES
      </div>
    </div>
  );
};

import react, { useState } from "react";
import "./style.css";

export const Actividades = ({ updateActividadConfig }) => {
  const [bloqueEdad, setBloqueEdad] = useState("4-5");
  const DesignV1 = ({ icon, n1, n2, n3 }) => {
    return (
      <div style={{ padding: "5px 35px", fontWeight: "bolder", fontSize: "25px", lineHeight: "1.2", textAlign: "end", width: "90px", float: "right", color: "#52a38d" }}>
        <p style={{ margin: "0px" }}>{n1}</p>
        <p style={{ margin: "0px", borderBottom: "1px #52a38d solid", marginBottom: "3px" }}>
          {bloqueEdad === "4-5" && (
            <>
              <label>{icon}</label>
              <label style={{ paddingLeft: "50px" }}>{n2}</label>
            </>
          )}
          {bloqueEdad === "6-7" && (
            <>
              {icon === "x" ? <div style={{ rotate: "135deg", marginTop: "-30px" }}>+</div> : <label>{icon}</label>}
              <label style={{ paddingLeft: "30px" }}>{n2}</label>
            </>
          )}
        </p>
        <p style={{ margin: "0px" }}>{n3}</p>
      </div>
    );
  };

  const DesignV2 = ({ n1, tipo }) => {
    return (
      <div style={{ padding: "5px 35px", fontWeight: "bolder", fontSize: "25px", lineHeight: "1.2", textAlign: "end", width: "90px", float: "right", color: "#52a38d" }}>
        <p style={{ fontFamily: "escolar_dotted", fontSize: "100px", marginTop: tipo === "letra" ? "-30%" : "-5%", textAlign: "center", marginLeft: "15px" }}>{n1}</p>
      </div>
    );
  };

  const DesignV21 = ({ n1, tipo }) => {
    return (
      <div style={{ padding: "5px 20px", fontWeight: "bolder", fontSize: "25px", lineHeight: "1.2", textAlign: "end", width: "90px", float: "right", color: "#52a38d" }}>
        <p style={{ fontFamily: "escolar_dotted", fontSize: "100px", marginTop: tipo === "letra" ? "-30%" : "-5%", textAlign: "center", marginLeft: "-30px" }}>{n1}</p>
      </div>
    );
  };

  const actividades = () => {
    switch (bloqueEdad) {
      case "4-5":
        return [
          { design: <DesignV1 icon={"+"} n1={"1"} n2={"2"} n3={"3"} />, name: "Sumas", edad: "4 a 5 años", tipo: "suma", dificultad: 1 },
          { design: <DesignV1 icon={"-"} n1={"3"} n2={"1"} n3={"2"} />, name: "Restas", edad: "4 a 5 años", tipo: "resta", dificultad: 1 },
          { design: <DesignV1 icon={"+"} n1={"20"} n2={"5"} n3={"25"} />, name: "Sumas II", edad: "5 a 6 años", tipo: "suma", dificultad: 2 },
          { design: <DesignV1 icon={"-"} n1={"20"} n2={"5"} n3={"15"} />, name: "Restas II", edad: "5 a 6 años", tipo: "resta", dificultad: 2 },
          { design: <DesignV2 n1={"3"} />, name: "Trazos", edad: "4 a 5 años", tipo: "trazo", dificultad: 1 },
          { design: <DesignV2 n1={"a"} tipo={"letra"} />, name: "Trazos", edad: "5 a 6 años", tipo: "trazo", dificultad: 2 },
          { design: <DesignV21 n1={"ma"} tipo={"letra"} />, name: "Trazos", edad: "5 a 6 años", tipo: "trazo", dificultad: 3 },
        ];
      case "6-7":
        return [
          { design: <DesignV1 icon={"+"} n1={"20"} n2={"35"} n3={"55"} />, name: "Sumas", edad: "6 a 7 años", tipo: "suma", dificultad: 3 },
          { design: <DesignV1 icon={"-"} n1={"20"} n2={"15"} n3={"5"} />, name: "Restas", edad: "6 a 7 años", tipo: "resta", dificultad: 3 },
          { design: <DesignV1 icon={"x"} n1={"3"} n2={"2"} n3={"6"} />, name: "Multiplicaciones", edad: "6 a 7 años", tipo: "multiplicacion", dificultad: 1 },
          { design: <DesignV1 icon={"x"} n1={"8"} n2={"5"} n3={"40"} />, name: "Multiplicaciones II", edad: "6 a 7 años", tipo: "multiplicacion", dificultad: 2 },
        ];
      default:
        return [];
    }
  };

  return (
    <>
      <div style={{ display: "flex", margin: "2% 5%", marginTop: "10%" }}>
        <div className={bloqueEdad === "4-5" ? "menuActive menu" : "menu"} onClick={() => setBloqueEdad("4-5")}>
          4 a 6 años
        </div>
        <div className={bloqueEdad === "6-7" ? "menuActive menu" : "menu"} onClick={() => setBloqueEdad("6-7")}>
          6 a 7 años
        </div>
        <div className={"menu"} style={{ color: "lightgrey" }}>
          Más niveles próximamente
        </div>
      </div>
      <hr style={{ margin: "-2% 5%", border: "0.5px #5ca48d87 solid" }} />

      <div className="blockActividades">
        {actividades().map((a) => {
          return (
            <div className="actividad" onClick={() => updateActividadConfig({ tipo: a.tipo, dificultad: a.dificultad })}>
              <div className="actividadNombre">{a.name}</div>
              <div className="actividadEjemplo">{a.design}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

import { Barra } from "./styled";
export const BarraStatus = ({ valor, color, deg, text }) => {
  return (
    <>
      <div style={{ marginLeft: "10px", marginBottom: "-8px" }}>
        {text}: {10 - valor / 10}
      </div>
      <Barra valor={valor} color={color} deg={deg} />
    </>
  );
};
